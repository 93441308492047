$(function() {
	
	
	// Overlay Menu Toggle
	
	$("[data-toggle=menu]").on("click", function() {
		if (!$("body").hasClass("show-menu")) {
			$("#meco-overlay-menu").css("display", "block");
			setTimeout(function() {
				$("body").addClass("show-menu");
			}, 50);
			
			if (!$("#meco-overlay-menu #categories").length) {
				$("#meco-overlay-menu").append($('#categories'));
			}
			
			if ($("#meco-overlay-menu #categories .navbar-categories-left").length > 1) {
				$("#meco-overlay-menu #categories .navbar-categories-left:last").remove();
			}
		} else {
			$("body").removeClass("show-menu");
			setTimeout(function() {
				$("#meco-overlay-menu").css("display", "none");
			}, 500);
			
			if (!$("#header #categories").length) {
				$("#header").append($('#categories'));
			}
		}
	});
	
	function open_cart(e) {
		e.preventDefault();
		if (!$("body").hasClass("show-cart") && $("#cart-container:visible").length < 1) {
			window.scrollTo(0, 0);
			$("#offcanvas-cart-overlay").css("display", "block");
			setTimeout(function() {
				$("body").addClass("show-cart");
			}, 50);
		} else {
			$("body").removeClass("show-cart");
			setTimeout(function() {
				$("#offcanvas-cart-overlay").css("display", "none");
			}, 500);
		}
		
		if (e.data.auto_close) {
			setTimeout(close_cart, 3000);
		}
	}
	
	function close_cart() {
		$("body").removeClass("show-cart");
		setTimeout(function() {
			$("#offcanvas-cart-overlay").css("display", "none");
		}, 500);
	}
	
	
	// Offcanvas Cart Toggle
	
	$("[data-toggle=cart]").on("click", {auto_close: false}, open_cart);
	$("#offcanvas-cart-overlay").on("click", close_cart);
	
	$('body').on("CART_DROPDOWN_OPEN", {auto_close: true}, open_cart);
	
	// Shopping Cart Product Count
	
	setTimeout(function() {
		$("body").trigger("CART_DROPDOWN_UPDATE");
	}, 1500);
	
	// Add special class to body element, if the visitor is using IE11
	if (!!navigator.userAgent.match(/Trident.*rv\:11\./)) {
		$("body").addClass("ie11");
	}
    
    // Disable next and previous arrows if current item is the last slide
    $('[data-wrap="false"]').each(function(index, carousel) {
        $(carousel).on('slid.bs.carousel', function () {
            let leftCarouselArrow = $(carousel).find('.left.carousel-control');
            let rightCarouselArrow = $(carousel).find('.right.carousel-control');
            
            let fistCarouselSlide = $(carousel).find('.item:first-child');
            if(fistCarouselSlide.hasClass('active')) {
                $(leftCarouselArrow).addClass('disabled');
            } else {
                $(leftCarouselArrow).removeClass('disabled');
            }
            
            let lastCarouselSlide = $(carousel).find('.item:last-child');
            if(lastCarouselSlide.hasClass('active')) {
                $(rightCarouselArrow).addClass('disabled');
            } else {
                $(rightCarouselArrow).removeClass('disabled');
            }
        });
    });
	
	
	// // Category Pages Left Sidebar Behavior
	
	// if ($("body.page-index-type-cat").length) {
	
	//     var $left = $("#left"),
	//        $listing = $(".category-product-listing"),
	//        $categories = $(".box-categories", $left),
	//        $filter = $(".box-filter", $left),
	//        leftIsInvisible = $left.css("display") == "none";
	
	//     if (leftIsInvisible && $categories.length) {
	
	//        var sidebarNeeded = false;
	
	//        // check if category menu has sub categories
	
	//        var $listItems = $("li", $categories),
	//            $activeListItem = $("li.active", $categories);
	
	//        if ($listItems.length && !$activeListItem.length) {
	//            sidebarNeeded = true;
	//        }
	
	//        // check if filters available
	
	
	//        // wrap product_listing
	//        $sidebar = $("<div class='category-sidebar col-md-3'></div>");
	//        $listing
	//            .addClass("row")
	//            .wrapInner("<div class='col-md-9'></div>")
	//            .prepend($sidebar);
	//        $categories.appendTo($sidebar);
	//        $filter.appendTo($sidebar);
	//     }
	// }
});